@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .animate{
      display: flex;
      animation: bounce 1s infinite;
    }
    .animate-spin{
    animation: spin 1s linear infinite;
}
    .animate-pulse{
        	animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }

@keyframes pulse {
  0%, 100% {
    opacity: 2;
  }
  50% {
    opacity: .5;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
  @keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  }}
  * {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
  }
  #root {
    height: 100%;
  }
  
  html,
  body {
    min-height: 100vh;
    height: 100%;
  }
  
  span {
    font-size: 40px;
  }
  
  .dark-mode {
    background-color: #1a1919;
    color: #999;
    
    transition: background-color 0.25s ease-in-out;
  }
  
  .light-mode {
    background-color: #fff;
    color: #333;

    transition: background-color 0.5s ease-in-out;
  }
  
 
  
 
  
  
  
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: #19a2c1;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .switch-checkbox {
    padding: 10px;
  }